<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/iotConfigManage' }">组态管理</el-breadcrumb-item>
          <el-breadcrumb-item>数据统计</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="form-box">
        <el-form :model="form" ref="ruleForm" label-width="100px">
          <div class="form-title">数据统计</div>
          <div class="static-device" v-for="(item,index) in gatewayNodes" :key="index">
            <p class="device-title">
                <i class="iconfont icon-wangguanshebei"></i>
                {{item.gatewayEid}}
            </p>
            <div v-for="(dItem,dIndex) in item.equipmentNodes" :key="dIndex">
                <div class="equip-node">{{dItem.eid}}</div>
                <div class="static-con">
                    <div class="static-tag" v-for="(tItem,tIndex) in dItem.tagNodes" :key="tIndex">
                        <p class="tag-top">{{tItem.tag}}</p>
                        <p class="desc-bottom">{{tItem.describe}}</p>
                    </div>
                </div>
            </div>
          </div>
          <div class="bottom-btn">
            <el-button type="primary" size="small" @click="$router.push('/iotConfigManage')">取消</el-button>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {},
      row: {},
      loading: false,
      gatewayNodes: [],
    };
  },
  methods: {
    loadDetailData() {
      this.loading = true
      this.$ajax.post('configurationDetail', {}, { configurationId: this.row }).then(res => {
        this.gatewayNodes = res.data.functionList.gatewayNodes
        this.loading = false
      }).catch(err=>{
        this.loading = false
      })
    }
  },
  mounted() {
    if (this.$route.params.row == null) {
      this.$router.push('/iotConfigManage')
    } else {
      this.row = this.$route.params.row
      this.loadDetailData()
    }
  },
};
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
.static-device {
    padding: 5px 20px;
    background: #FFF;
    border: 1px solid #efefef;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(230, 230, 230, 0.5);
    margin: 10px 0;
}
.device-title {
    font-size: 17px;
    color: #333;
    border-bottom: 1px solid #efefef;
    padding-bottom: 10px;
}
.equip-node {
    font-size:14px;
    color:#333;
    border-radius:4px;
    padding: 5px 10px;
    margin: 0 300px;
    background: #efefef;
    text-align: center;
}
.static-con {
    display:flex;
    align-items:center;
    width:100%;
    flex-wrap:wrap;
    margin-top: 10px;
    margin-bottom:15px;
}
.static-tag {
    border-left: 4px solid #409eff;
    border-radius: 4px;
    font-size: 13px;
    margin: 10px 9px;
    width: 146px;
    background: #f4f9fd8a;
    transition: all .3s ease;
}
.tag-top {
    margin-left: 10px;
    font-weight: bold;
    border-radius: 4px;
    font-size: 14px;
    color: #333;
}
.desc-bottom {
    margin-left: 10px;
    color: #7D8592;
    line-height: 10px;
    font-size: 12px;
}
.static-tag:hover {
    border-left: 4px solid #409eff;
    background: #409eff;
}
.static-tag:hover .tag-top{
    color: #FFF;
}
.static-tag:hover .desc-bottom{
    color: #FFF;
}
</style>
<style lang="less">
</style>
